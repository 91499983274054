  export interface Service {
    title: string;
    description: string;
    aosDelay: number;
    pricePerDay: number;
    postProductionPricePerDay: number;
    supOperatorPrice?: number;
  }
  
  export interface ServiceCategory {
    title: string;
    services: Service[];
  }

  export interface ServiceTitle {
    value: string;
    label: string;
  }

  export type SelectedService = {
    service: Service;
    productionDays: number;
    postProductionOption: 'simple' | 'multiple';
    additionalOperators: number; // Nombre d'opérateurs supplémentaires pour ce service
  };
  
  export const isVideoCategory = (categoryTitle: string): boolean => {
    return categoryTitle.toLowerCase().includes('vidéo');
  };
  
  export const extractServiceTitles = (categories: ServiceCategory[]): ServiceTitle[] => {
    const titles: ServiceTitle[] = [];
  
    categories.forEach(category => {
      const categoryPrefix = isVideoCategory(category.title) ? 'Vidéo' : '';
  
      category.services.forEach(service => {
        titles.push({
          value: `${categoryPrefix} ${service.title}`,
          label: `${categoryPrefix} ${service.title}`,
        });
      });
    });
  
    return titles;
  };
  
 // servicesData.ts

export const serviceCategories: ServiceCategory[] = [
  {
    title: 'Production vidéo',
    services: [
      {
        title: 'Présentation de l\'entreprise',
        description: 'Vidéo expliquant votre histoire, valeurs et mission.',
        aosDelay: 100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Interview des dirigeants',
        description: 'Interviews des dirigeants pour présenter leur vision et stratégie.',
        aosDelay: 300,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Démonstration de produits',
        description: 'Vidéos montrant les fonctionnalités et avantages des produits.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Présentation de services',
        description: 'Vidéos explicatives des services proposés.',
        aosDelay: 500,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Tutoriels d\'utilisation',
        description: 'Tutoriels pour aider à utiliser vos produits ou services.',
        aosDelay: 600,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Couverture de séminaires',
        description: 'Vidéos capturant l\'essence de vos séminaires.',
        aosDelay: 700,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Lancement de produit',
        description: 'Vidéos documentant le lancement de vos produits.',
        aosDelay: 800,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Activités de team building',
        description: 'Vidéos montrant la cohésion et l\'engagement des équipes.',
        aosDelay: 900,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Spot publicitaire',
        description: 'Vidéos publicitaires pour promouvoir vos produits ou services.',
        aosDelay: 1000,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Annonce spéciale',
        description: 'Vidéos pour des promotions ou événements importants.',
        aosDelay: 1100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Campagne marketing',
        description: 'Vidéos pour booster vos campagnes marketing.',
        aosDelay: 1200,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
    ],
  },
  {
    title: 'Services en photographie',
    services: [
      {
        title: 'Photos de portraits professionnels',
        description: 'Portraits professionnels de haute qualité pour les employés.',
        aosDelay: 100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de produits',
        description: 'Photos détaillées et attrayantes de vos produits.',
        aosDelay: 200,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos événementielles',
        description: 'Photos professionnelles pour les séminaires et conférences.',
        aosDelay: 300,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de l\'environnement de travail',
        description: 'Photos de vos bureaux et espaces de travail.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de communication',
        description: 'Photos percutantes pour brochures et supports marketing.',
        aosDelay: 500,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
    ],
  },
];
