import React from 'react';

const MentionsLegales: React.FC = () => {
  return (
    <div className="bg-gray-800 text-white min-h-screen py-16">
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold mb-8 text-center">Mentions Légales</h1>
{/*         
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Informations Générales</h2>
          <p><strong>Nom de l'entreprise :</strong> WIDE PIXEL</p>
          <p><strong>Adresse :</strong> [Votre Adresse]</p>
          <p><strong>Téléphone :</strong> [Votre Numéro de Téléphone]</p>
          <p><strong>Email :</strong> [Votre Adresse Email]</p>
          <p><strong>Site web :</strong> [Votre site web]</p>
          <p><strong>Forme juridique :</strong> [Votre Forme Juridique]</p>
          <p><strong>Capital social :</strong> [Montant du Capital Social]</p>
          <p><strong>Numéro SIRET :</strong> [Votre Numéro SIRET]</p>
          <p><strong>Numéro de TVA intracommunautaire :</strong> [Votre Numéro de TVA]</p>
        </section> */}

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Hébergement</h2>
          <p><strong>Hébergeur :</strong> Netlify</p>
          <p><strong>Site web :</strong> https://www.netlify.com/</p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Propriété Intellectuelle</h2>
          <p>
            Tous les contenus présents sur ce site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société WIDE PIXEL à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Protection des Données Personnelles</h2>
          <p>
            Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée, vous disposez d'un droit d'accès, de rectification, de modification et de suppression des données qui vous concernent. Vous pouvez exercer ce droit en envoyant un courrier à l'adresse suivante : [Votre Adresse Postale] ou par email à [Votre Adresse Email].
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Responsabilité</h2>
          <p>
            La société WIDE PIXEL met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de WIDE PIXEL, et signaler toutes modifications du site qu'il jugerait utile. WIDE PIXEL n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Liens Hypertextes</h2>
          <p>
            Les sites internet de peuvent offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. WIDE PIXEL ne dispose d'aucun moyen pour contrôler les sites en connexion avec ses sites internet. WIDE PIXEL ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l'internaute, qui doit se conformer à leurs conditions d'utilisation.
          </p>
        </section>
      </div>
    </div>
  );
};

export default MentionsLegales;