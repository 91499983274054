import React, { useState } from 'react';
import { SelectedService, Service } from '../services/servicesData';
import { serviceCategories } from '../services/servicesData';

// Obtenir le code d'accès depuis les variables d'environnement
const ACCESS_CODE = (process.env.REACT_APP_ACCESS_CODE || '').toUpperCase();

const QuoteSimulator = () => {
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [accessCode, setAccessCode] = useState<string>(''); // État pour le code d'accès
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false); // État pour vérifier l'accès

  const handleServiceChange = (service: Service, isChecked: boolean) => {
    if (isChecked) {
      setSelectedServices([...selectedServices, { 
        service, 
        productionDays: 1, 
        postProductionOption: 'simple', // Valeur par défaut pour les options de post-production
        additionalOperators: 0 // Valeur par défaut pour le nombre d'opérateurs supplémentaires
      }]);
    } else {
      setSelectedServices(selectedServices.filter(s => s.service.title !== service.title));
    }
  };

  const handleDaysChange = (service: Service, days: number) => {
    setSelectedServices(
      selectedServices.map(s =>
        s.service.title === service.title ? { ...s, productionDays: days } : s
      )
    );
  };

  const handlePostProductionChange = (service: Service, option: 'simple' | 'multiple') => {
    setSelectedServices(
      selectedServices.map(s =>
        s.service.title === service.title ? { ...s, postProductionOption: option } : s
      )
    );
  };

  const handleAdditionalOperatorsChange = (service: Service, additionalOperators: number) => {
    setSelectedServices(
      selectedServices.map(s =>
        s.service.title === service.title ? { ...s, additionalOperators } : s
      )
    );
  };

  const handleAccessCodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (accessCode.toUpperCase() === ACCESS_CODE) {
      setIsAuthorized(true);
    } else {
      alert('Code d\'accès incorrect. Veuillez réessayer.');
    }
  };

  const totalPrice = selectedServices.reduce((total, s) => {
    const basePrice = s.service.pricePerDay * s.productionDays;
    const postProductionMultiplier = s.postProductionOption === 'multiple' ? 1.5 : 1;
    const postProductionPrice = s.service.postProductionPricePerDay * s.productionDays * postProductionMultiplier;
    
    // Calculer le prix supplémentaire pour les opérateurs caméra
    const operatorPrice = s.service.supOperatorPrice ?? 0;
    const additionalOperatorCost = s.additionalOperators > 0 ? operatorPrice * s.additionalOperators : 0;

    return total + basePrice + postProductionPrice + additionalOperatorCost;
  }, 0);

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-900 rounded-lg shadow-lg">
      {!isAuthorized ? (
        <div className="flex items-center justify-center h-screen">
          <form onSubmit={handleAccessCodeSubmit} className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
            <h1 className="text-2xl font-bold text-teal-300 mb-4">Entrer le Code d'Accès</h1>
            <label className="block mb-4 text-gray-200">
              Code:
              <input
                type="password"
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                className="ml-2 p-2 border border-gray-600 rounded-md bg-gray-700 text-gray-100"
              />
            </label>
            <button 
              type="submit"
              className="px-6 py-2 bg-teal-500 text-white rounded-md shadow hover:bg-teal-600 transition"
            >
              Valider
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className="container mb-12 text-center">
            <h1 className="text-4xl font-bold text-center mb-8 text-teal-300">Simulateur de devis</h1>
            <p className="text-gray-300 italic">
              Ce devis est à titre indicatif seulement et doit être validé. Le prix final peut varier en fonction des précisions supplémentaires fournies par le client.
            </p>
          </div>
          <form>
            {serviceCategories.map((category) => (
              <div key={category.title} className="mb-8 p-4 bg-gray-800 rounded-lg shadow-md border border-gray-700">
                <h2 className="text-2xl font-semibold mb-4 text-gray-200">{category.title}</h2>
                {category.services.map((service) => (
                  <div key={service.title} className="mb-4 border-b border-gray-600 pb-4">
                    <label className="flex items-center mb-2 text-gray-300">
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(e) => handleServiceChange(service, e.target.checked)}
                      />
                      {service.title}
                    </label>
                    {selectedServices.some(s => s.service.title === service.title) && (
                      <div className="ml-6">
                        <label className="block mb-2 text-gray-200">
                          Nombre de jours de présence:
                          <input
                            type="number"
                            className="ml-2 p-2 border border-gray-600 rounded-md bg-gray-700 text-gray-100"
                            min="1"
                            value={
                              selectedServices.find(s => s.service.title === service.title)?.productionDays || 1
                            }
                            onChange={(e) =>
                              handleDaysChange(service, Number(e.target.value))
                            }
                          />
                        </label>
                        {category.title === 'Production vidéo' && (
                          <label className="block mb-2 text-gray-200">
                            Option de post-production:
                            <select
                              className="ml-2 p-2 border border-gray-600 rounded-md bg-gray-700 text-gray-100"
                              value={
                                selectedServices.find(s => s.service.title === service.title)?.postProductionOption || 'simple'
                              }
                              onChange={(e) =>
                                handlePostProductionChange(service, e.target.value as 'simple' | 'multiple')
                              }
                            >
                              <option value="simple">Une seule vidéo</option>
                              <option value="multiple">Plusieurs vidéos</option>
                            </select>
                          </label>
                        )}
                        {service.supOperatorPrice && (
                          <label className="block mb-2 text-gray-200">
                            Nombre d'opérateurs supplémentaires:
                            <input
                              type="number"
                              className="ml-2 p-2 border border-gray-600 rounded-md bg-gray-700 text-gray-100"
                              min="0"
                              value={
                                selectedServices.find(s => s.service.title === service.title)?.additionalOperators || 0
                              }
                              onChange={(e) => handleAdditionalOperatorsChange(service, Number(e.target.value))}
                            />
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </form>
          <div className="text-2xl font-semibold mt-8 text-gray-200">
            Prix Total: <span className="text-teal-300">{totalPrice.toFixed(2)}€ HT</span>
          </div>
          <button 
            className="mt-4 px-6 py-2 bg-teal-500 text-white rounded-md shadow hover:bg-teal-600 transition"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Masquer les Détails' : 'Voir les Détails'}
          </button>
          {showDetails && (
            <div className="mt-4 p-4 bg-gray-800 rounded-lg shadow-md border border-gray-700">
              <h2 className="text-xl font-semibold text-gray-200">Détails du Calcul</h2>
              <ul>
                {selectedServices.map((s) => (
                  <li key={s.service.title} className="mt-2 text-gray-300">
                    {s.service.title} - {s.productionDays} jour(s)
                    {s.postProductionOption === 'multiple' && ' - Post-production multiple'}
                    {s.additionalOperators > 0 && (
                      ` - ${s.additionalOperators} opérateur(s) supplémentaire(s)`
                    )}
                    <div>
                      Coût de base: {(s.service.pricePerDay * s.productionDays).toFixed(2)}€
                    </div>
                    <div>
                      Post-production: {(s.service.postProductionPricePerDay * s.productionDays * (s.postProductionOption === 'multiple' ? 1.5 : 1)).toFixed(2)}€
                    </div>
                    <div>
                      Coût opérateurs supplémentaires: {(s.additionalOperators * (s.service.supOperatorPrice || 0)).toFixed(2)}€
                    </div>
                  </li>
                ))}
              </ul>
              <h3 className="mt-6 text-gray-300 italic">
                Les frais de post-production incluent le montage ainsi que divers frais supplémentaires tels que les logiciels de montage et retouches, les licences de musique et l'amortissement du matériel caméras et lumières.
              </h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuoteSimulator;