import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { serviceCategories } from '../services/servicesData'; 

const ServicesPage = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 800, // Durée de l'animation
    });
  }, []);

  return (
    <div>
      {serviceCategories.map((category, categoryIndex) => (
        <section key={categoryIndex} className="py-16 bg-gray-800 text-white">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-12" data-aos="fade-up">
              {category.title}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {category.services.map((service, serviceIndex) => (
                <div
                  key={serviceIndex}
                  className="bg-gray-700 p-6 rounded-lg shadow-lg"
                  data-aos="fade-right"
                  data-aos-delay={service.aosDelay}
                >
                  <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
                  <p className="text-s">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default ServicesPage;