import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Select, { MultiValue, ActionMeta } from 'react-select';
import { extractServiceTitles, serviceCategories } from '../services/servicesData'; 


// Définir les options des services
const servicesOptions = extractServiceTitles(serviceCategories);

// Définir les types pour les options de Select
interface OptionType {
  value: string;
  label: string;
}

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    requestType: '',
    services: [] as string[]
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelectChange = (newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    setFormData({
      ...formData,
      services: newValue ? newValue.map(option => option.value) : []
    });
    // Fermer le menu si plus d'options disponibles
    if (newValue.length === 0) {
      setSelectOpen(false);
    }
  };

  const handleSelectMenuOpen = () => setSelectOpen(true);
  const handleSelectMenuClose = () => setSelectOpen(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID as string
    )
    .then((response) => {
      setSuccessMessage('Votre message a été envoyé avec succès!');
      setFormData({ name: '', email: '', message: '', requestType: '', services: [] });
    })
    .catch((error) => {
      console.error('EmailJS Error:', error);
      setErrorMessage('Une erreur est survenue lors de l\'envoi du message.');
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  const selectedOptions = servicesOptions.filter(option =>
    formData.services.includes(option.value)
  );

  return (
    <div className="bg-gray-900 text-white py-16" data-aos="fade-down" data-aos-delay="600">
      <div className="container mx-auto p-6">
        <div className="text-center">
          <h2 className="text-4xl font-bold mb-2">Contactez-nous</h2>
          <p className="text-m mb-6 text-gray-300">
            Pour toute question ou demande de devis.
          </p>
        </div>
        <div className="flex justify-center">
          <form onSubmit={handleSubmit} className={`w-full max-w-lg bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-700 ${selectOpen ? 'pb-40' : 'pb-16'}`}>
            <div className="mb-6">
              <label htmlFor="name" className="block text-sm font-medium mb-2">Nom *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition-all duration-300 ease-in-out bg-gray-900 text-white"
                placeholder="Votre nom"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium mb-2">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition-all duration-300 ease-in-out bg-gray-900 text-white"
                placeholder="Votre email"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="requestType" className="block text-sm font-medium mb-2">Type de demande *</label>
              <select
                id="requestType"
                name="requestType"
                value={formData.requestType}
                onChange={handleChange}
                required
                className="block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition-all duration-300 ease-in-out bg-gray-900 text-white"
              >
                <option value="" disabled>Objet de la demande</option>
                <option value="question">Question</option>
                <option value="devis">Demande de devis</option>
              </select>
            </div>
            {formData.requestType === 'devis' && (
              <div className="mb-6 relative">
                <label htmlFor="services" className="block text-sm font-medium mb-2">Services souhaités</label>
                <Select
                  id="services"
                  isMulti
                  options={servicesOptions}
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  onMenuOpen={handleSelectMenuOpen}
                  onMenuClose={handleSelectMenuClose}
                  menuIsOpen={selectOpen}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Choisissez les services"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: '#111827',
                      borderColor: '#4B5563',
                      borderRadius: '0.375rem',
                      color: 'white',
                      minHeight: '38px',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: '#1F2937',
                      color: 'white',
                      marginTop: '4px',
                      zIndex: 9999,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? '#3B82F6' : '#1F2937',
                      color: state.isSelected ? 'white' : '#E5E7EB',
                      ':active': {
                        backgroundColor: '#3B82F6',
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: '#3B82F6',
                      color: 'white',
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: 'white',
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      color: 'white',
                      ':hover': {
                        backgroundColor: '#3B82F6',
                        color: 'white',
                      },
                    }),
                  }}
                />
              </div>
            )}
            <div className="mb-6">
              <label htmlFor="message" className="block text-sm font-medium mb-2">Message *</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={6}
                className="block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition-all duration-300 ease-in-out bg-gray-900 text-white"
                placeholder="Votre message"
              />
            </div>
            {successMessage && <p className="text-green-400 mb-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-400 mb-4">{errorMessage}</p>}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-transform duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'transform hover:scale-105'}`}
            >
              {isSubmitting ? 'Envoi...' : 'Envoyer'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
